.pageNotFound {
    padding: 100px 0 100px 0;
    margin: auto auto;
    width: -moz-fit-content;
    width: fit-content;
}
.number {
    text-align: center;
    font-size: 200px;
}
.info {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 68px;
    max-width: 620px;
    display: block;
}
.buttonBack {
    margin: 0 auto;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
}
@media screen and (max-width: 768px) {
    .number {
        font-size: 100px;
    }
    .info {
        padding: 0 32px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
    }
}
